export const music = [
    {
        src: "/Music/military_band1.png",
        alt: "military band pic #1"
    },
    {
        src: "/Music/military_band2.png",
        alt: "military band pic #2"
    },
    {
        src: "/Music/band.png",
        alt: "concert band"
    }
]