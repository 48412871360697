export const MenuItems = [
    {
        title: "Home",
        url: "/",
        cName: "nav-links"
    },
    {
        title: "About",
        url: "/about",
        cName: "nav-links"
    },
    {
        title: "Projects",
        url: "/projects",
        cName: "nav-links"
    },
    // {
    //     title: "Resume",
    //     url: "Polly_Liu_Resume.pdf",
    //     cName: "nav-links"
    // },
    {
        title: "Contact",
        url: "/contact",
        cName: "nav-links"
    },
]