export const frisbee = [
    {
        src: "/Frisbee/pancake.jpg",
        alt: "pancake"
    },
    {
        src: "/Frisbee/team_1.jpg",
        alt: "team pic #1"
    },
    {
        src: "/Frisbee/team_2.jpg",
        alt: "team pic #2"
    }
]